import React from "react";
import loaderSvg from "../assets/loader.svg";

// component that displays a loading spinner
export const PageLoader = () => {
  // render loading spinner image
  return (
    <div className="loader">
      <img src={loaderSvg} alt="Loading..." />
    </div>
  );
};
