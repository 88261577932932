import React from "react";
import { NavBarTabs } from "./nav-bar-tabs";
import { NavBarBrand } from "./nav-bar-brand";
import { NavBarButtons } from "./nav-bar-buttons";

// main navigation bar component that combines brand, tabs and buttons
export const NavBar = () => {
  return (
    // outer container for nav bar
    <div className="nav-bar__container">
      <nav className="nav-bar">
        <NavBarBrand />
        <NavBarTabs />
        <NavBarButtons />
      </nav>
    </div>
  );
};
