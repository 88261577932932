import "../styles/news-feed.css";
import { fetchWithRetry } from "./utils/api-utils";
import React, { useState, useEffect } from "react";

// component to display individual news items
const NewsCard = ({ title, date, link, description }) => {
  // helper function to format dates
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // render the news card with formatted date, title, description, and link
  return (
    <div
      className="news-tile"
      onClick={() => {
        window.open(link, "_blank", "noopener,noreferrer");
      }}
    >
      <h3 className="news-tile__title">{title}</h3>
      <p className="news-tile__description">{description}</p>
      <div className="news-tile__date">{formatDate(date)}</div>
    </div>
  );
};

// main component that fetches and displays the news feed
export const NewsFeed = ({ maxItems = null }) => {
  // state management for news items, loading state, and errors
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // fetch news data
  useEffect(() => {
    const fetchNews = async () => {
      try {
        // try to fetch news using retry utility
        const data = await fetchWithRetry({
          url: `${window.REACT_APP_BACKEND_URL}/api/v1/news`,
          onError: (error) => {
            console.error("Error fetching news:", error);
          },
        });
        setNews(maxItems ? data.items.slice(0, maxItems) : data.items);
      } catch (err) {
        // set error state if fetch fails
        setError("Failed to load news feed. Please try again later.");
      } finally {
        // set loading to false regardless of outcome
        setLoading(false);
      }
    };

    fetchNews();
  }, []);

  // show loading state while fetching data
  if (loading) {
    return <p>Loading news...</p>;
  }

  // show error message if fetch failed
  if (error) {
    return (
      <div className="message error-message">
        <p>{error}</p>
      </div>
    );
  }

  // render the list of news cards
  return (
    <div className="news-grid">
      {news.map((item, index) => (
        <NewsCard
          key={`${item.link}-${index}`}
          title={item.title}
          date={item.pubDate || item.date}
          link={item.link}
          description={item.description || item.content}
        />
      ))}
    </div>
  );
};
