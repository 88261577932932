import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

// login button component using auth0 authentication
export const LoginButton = () => {
  // get login function from auth0
  const { loginWithRedirect } = useAuth0();

  // handle login and redirect to auth0
  const handleLogin = async () => {
    await loginWithRedirect({
      // redirect to resources page after login
      appState: {
        returnTo: "/resources",
      },
      // force login prompt
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};
