import "../styles/help-page.css";
import newsImage from "../assets/news.png";
import React, { useState, useEffect } from "react";
import resourcesImage from "../assets/resources.png";
import { PageLayout } from "../components/page-layout";
import { PageLoader } from "../components/page-loader";
import profileAdminImage from "../assets/profile-admin.png";
import userManagementImage from "../assets/user-management.png";
import resourcesAdminImage from "../assets/resources-products.png";
import accountManagementImage from "../assets/account-management.png";

export const HelpPage = () => {
  // state for mobile toc toggle
  const [tocVisible, setTocVisible] = useState(true);

  // handle smooth scrolling to sections
  const scrollToSection = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // generate table of contents
  const generateTableOfContents = () => {
    return (
      <div className="help-sidebar">
        <div className="help-sidebar-header">
          <h3>Contents</h3>
        </div>
        <ul className="toc-list show-mobile">
          <li>
            <a
              href="#overview"
              className="section-link"
              onClick={(e) => scrollToSection(e, "overview")}
            >
              Overview
            </a>
          </li>
          <li>
            <a
              href="#accessing"
              className="section-link"
              onClick={(e) => scrollToSection(e, "accessing")}
            >
              Accessing the Customer Portal
            </a>
          </li>
          <li>
            <a
              href="#features"
              className="section-link"
              onClick={(e) => scrollToSection(e, "features")}
            >
              Customer Portal Features
            </a>
          </li>
          <li>
            <a
              href="#the-resources-tab"
              className="subsection-link"
              onClick={(e) => scrollToSection(e, "the-resources-tab")}
            >
              Resources Tab
            </a>
          </li>
          <li>
            <a
              href="#the-news-tab"
              className="subsection-link"
              onClick={(e) => scrollToSection(e, "the-news-tab")}
            >
              News Tab
            </a>
          </li>
          <li>
            <a
              href="#the-account-management-tab"
              className="subsection-link"
              onClick={(e) => scrollToSection(e, "the-account-management-tab")}
            >
              Account Management Tab
            </a>
          </li>
          <li>
            <a
              href="#troubleshooting"
              className="section-link"
              onClick={(e) => scrollToSection(e, "troubleshooting")}
            >
              Troubleshooting
            </a>
          </li>
          <li>
            <a
              href="#requests"
              className="section-link"
              onClick={(e) => scrollToSection(e, "requests")}
            >
              Requests
            </a>
          </li>
          <li>
            <a
              href="#support"
              className="section-link"
              onClick={(e) => scrollToSection(e, "support")}
            >
              Support
            </a>
          </li>
        </ul>
      </div>
    );
  };
  const [configValues, setConfigValues] = useState({
    domain: "",
    supportPortalUrl: "",
    supportEmail: "",
    brand: window.REACT_APP_BRAND || "",
  });
  const [isLoading, setIsLoading] = useState(true);

  // check for config values periodically until they're available
  useEffect(() => {
    const checkConfigInterval = setInterval(() => {
      const domain = window.REACT_APP_DOMAIN;
      const supportPortalUrl = window.REACT_APP_SUPPORT_PORTAL_URL;
      const supportEmail = window.REACT_APP_SUPPORT_EMAIL;

      if (domain && supportPortalUrl && supportEmail) {
        setConfigValues({
          domain,
          supportPortalUrl,
          supportEmail,
          brand: window.REACT_APP_BRAND || "",
        });
        setIsLoading(false);
        clearInterval(checkConfigInterval);
      }
    }, 100); // check every 100ms

    // timeout after 5 seconds to prevent infinite loading
    const timeout = setTimeout(() => {
      clearInterval(checkConfigInterval);
      setIsLoading(false);
    }, 5000);

    return () => {
      clearInterval(checkConfigInterval);
      clearTimeout(timeout);
    };
  }, []);

  if (isLoading) {
    return <PageLoader />;
  }

  const { domain, supportPortalUrl, supportEmail, brand } = configValues;
  return (
    <div className="page-background">
      <PageLayout>
        <div className="page-content">
          <div className="content-layout">
            <h1 className="content__title">{brand} Customer Portal Help</h1>
            <div className="help-page-container">
              {generateTableOfContents()}
              <div className="help-main-content">
                <div className="content__body help-content">
                  <section id="overview">
                    <h2>Overview</h2>
                    <p>
                      The {brand} Customer Portal is your central hub, providing
                      access to resources, support, and account management for
                      all {brand} Cloud and On-prem users. Administrators gain
                      additional self-service tools for efficient user
                      management.
                    </p>
                    <ul>
                      <li>
                        Non-admin users will find the {brand} Customer Portal to
                        be useful as a quick way to access {brand} resources and
                        services.
                      </li>
                      <li>
                        Every {brand} customer selects an Administrator who uses
                        the Customer Portal's self-service user management
                        features. This gives Admins direct control over user
                        accounts and reduces reliance on {brand} Customer
                        Support for standard operations.
                      </li>
                    </ul>
                  </section>

                  <section id="accessing">
                    <h3>Accessing the Customer Portal</h3>
                    <p>To access the {brand} Customer Portal:</p>
                    <ol>
                      <li>
                        <p>
                          In your browser, go to{" "}
                          <a
                            href={`https://customer.${domain}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://customer.{domain}
                          </a>
                        </p>
                      </li>
                      <li>
                        Log in to the Customer Portal with your regular {brand}{" "}
                        credentials.
                      </li>
                    </ol>
                    <p>
                      The {brand} Customer Portal opens on the{" "}
                      <strong>Resources</strong> tab.
                    </p>
                    <p>
                      To logout of the Customer Portal, click your profile logo,
                      next to the top menu and select <strong>Logout</strong>.
                    </p>
                  </section>

                  <section id="features">
                    <h2>Customer Portal Features</h2>
                    <p>
                      The {brand} Customer Portal includes several useful
                      features:
                    </p>
                    <ul>
                      <li>
                        <a
                          href="#the-resources-tab"
                          onClick={(e) =>
                            scrollToSection(e, "the-resources-tab")
                          }
                        >
                          Resources
                        </a>
                      </li>
                      <li>
                        <a
                          href="#the-news-tab"
                          onClick={(e) => scrollToSection(e, "the-news-tab")}
                        >
                          News feed
                        </a>
                      </li>
                      <li>
                        <a
                          href="#the-account-management-tab"
                          onClick={(e) =>
                            scrollToSection(e, "the-account-management-tab")
                          }
                        >
                          Account Management
                        </a>
                      </li>
                    </ul>
                  </section>

                  <section id="the-resources-tab">
                    <h3>The Resources tab</h3>
                    <p>
                      The Resources tab opens by default when you log in to the
                      Customer Portal. Click a Services tile to navigate to any
                      one of a number of useful {brand} resources.
                    </p>
                    <p>Some of the services are:</p>
                    <ul>
                      <li>
                        <strong>Documentation:</strong> Access the {brand}{" "}
                        knowledgebase
                      </li>
                      <li>
                        <strong>Support Portal:</strong> Get help from the{" "}
                        {brand} support team
                      </li>
                      <li>
                        <strong>University:</strong> Access training and
                        educational resources
                      </li>
                      <li>
                        <strong>Ideas Portal:</strong> Share and view ideas for
                        new and improved {brand} features
                      </li>
                      <li>
                        <strong>Developer Portal:</strong> Access {brand}{" "}
                        documentation for developers
                      </li>
                    </ul>
                    <p>
                      When you log in to the {brand} Customer Portal, you
                      automatically gain access to all of the resources listed
                      in this section. So When you click any of the tiles, you
                      won't need to re-authenticate.
                    </p>
                    <p>
                      <img src={resourcesImage} alt="Resources tab" />
                    </p>
                    <p>
                      The Resources tab also includes links to all of your{" "}
                      {brand} environments in the <strong>Products</strong>{" "}
                      section.
                    </p>
                    <p>When you click a Product tile:</p>
                    <ul>
                      <li>
                        If it links to a {brand} Cloud instance, your {brand}{" "}
                        environment opens automatically.
                      </li>
                      <li>
                        If it links to a {brand} On-prem instance, the {brand}{" "}
                        login screen displays. Enter your credentials to
                        proceed.
                      </li>
                    </ul>
                    <p>
                      <img
                        src={resourcesAdminImage}
                        alt="Resources tab for admins"
                      />
                    </p>
                  </section>

                  <section id="the-news-tab">
                    <h3>The News tab</h3>
                    <p>
                      The News tab is a curated feed that automatically
                      refreshes itself with the latest {brand} news. Come back
                      often to read about the latest in {brand} initiatives and
                      to read professional blogs about topics relevant to you.
                    </p>
                    <p>
                      <img src={newsImage} alt="News tab" />
                    </p>
                  </section>

                  <section id="the-account-management-tab">
                    <h3>The Account Management tab</h3>
                    <p>
                      Manage your personal account settings within the Account
                      Management tab:
                    </p>
                    <ul>
                      <li>Securely reset your own password</li>
                      <li>
                        Enable multi-factor authentication (MFA) for added
                        protection from unauthorized access
                      </li>
                      <li>
                        View and copy your access token to download {brand}{" "}
                        release packages.
                      </li>
                    </ul>
                    <p>
                      <img
                        src={accountManagementImage}
                        alt="Account Management tab for admins"
                      />
                    </p>
                    <h4>Account Management for Admin users</h4>
                    <h5>Manage accounts for all users</h5>
                    <p>
                      Admins can manage accounts for all users in their
                      organization.
                    </p>
                    <p>To do so:</p>
                    <ol>
                      <li>Next to the top menu, click your profile logo.</li>
                      <li>
                        Select <strong>Admin</strong>.
                      </li>
                    </ol>
                    <p>
                      <img src={profileAdminImage} alt="Profile select Admin" />
                    </p>
                    <p>
                      The User Management page opens with a list of all {brand}{" "}
                      users registered with your organization.
                    </p>
                    <p>For each user, Admins can:</p>
                    <ul>
                      <li>View all user names and email addresses</li>
                      <li>
                        See if multi-factor authentication (MFA) is activated
                        for every user
                      </li>
                      <li>See each user's last login date and time</li>
                      <li>
                        Click the horizontal three-dot icon next to any user to
                        perform more actions:
                        <ul>
                          <li>
                            Edit the user: Change their name or email address,
                            and indicate if their email address is verified)
                          </li>
                          <li>Reset the user's password</li>
                          <li>Reset the user's MFA</li>
                        </ul>
                      </li>
                    </ul>
                    <p>
                      <img
                        src={userManagementImage}
                        alt="User Management for Admins"
                      />
                    </p>
                  </section>

                  <section id="troubleshooting">
                    <h2>Troubleshooting</h2>
                    <h4>Mobile View</h4>
                    <p>
                      When accessing the User Management page on mobile devices:
                    </p>
                    <ul>
                      <li>
                        The table may not display all columns due to limited
                        screen width
                      </li>
                      <li>
                        To view all user information, scroll horizontally by
                        swiping left or right on the table
                      </li>
                      <li>
                        Admin users can access the three-dot menu for each user
                        by scrolling all the way to the right side of the table
                      </li>
                      <li>
                        For the best experience, rotate your device to landscape
                        orientation to see more columns at once
                      </li>
                    </ul>
                    <p>
                      Note: Some administrative functions may be easier to
                      perform on a desktop or tablet device with a larger
                      screen.
                    </p>
                  </section>

                  <section id="requests">
                    <h2>Requests</h2>
                    <p>
                      To request access to {brand} services for new users in
                      your organization:
                    </p>
                    <ol>
                      <li>
                        Visit{" "}
                        <a
                          href={`https://signup.${domain}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          https://signup.{domain}
                        </a>
                      </li>
                      <li>
                        Complete the signup form with the required information
                        for the new user
                      </li>
                      <li>
                        Submit the request, which will be reviewed by {brand}{" "}
                        Customer Support.
                      </li>
                    </ol>
                    <p>
                      Once approved, the new user will receive an email with
                      instructions to set up their account and access the{" "}
                      {brand} Customer Portal.
                    </p>
                    <p>{brand} services:</p>
                    <ol>
                      <li>
                        <strong>University</strong> access includes access to{" "}
                        <a
                          href={window.REACT_APP_UNIVERSITY_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {brand} University.
                        </a>
                      </li>
                      <li>
                        <strong>Support Portal</strong> access includes access
                        to{" "}
                        <a
                          href={window.REACT_APP_SUPPORT_PORTAL_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {brand} Support.
                        </a>
                      </li>
                      <li>
                        <strong>Docs</strong> access includes access to the
                        following services:{" "}
                        <a
                          href={window.REACT_APP_DOCS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Docs
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_API_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          API
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_IDEAS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Ideas
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_DS_APPS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          DS Apps
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_DS_CONNECTORS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          DS Connectors
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_RELEASES_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Releases
                        </a>
                        ,{" "}
                        <a
                          href={window.REACT_APP_PARTNERS_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Partners
                        </a>
                        , and{" "}
                        <a
                          href={window.REACT_APP_DEVELOPER_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Developer
                        </a>
                        .
                      </li>
                    </ol>
                  </section>

                  <section id="support">
                    <h2>Support</h2>
                    <p>
                      If you have any questions about the Customer Portal, if
                      you're unable to resolve your issue using the resources
                      within this Customer Portal, or if you have questions
                      about your account, contact {brand} Customer Support.
                    </p>
                    <p>
                      You can submit a support ticket through the{" "}
                      <a
                        href={supportPortalUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {brand} Customer Support Portal
                      </a>{" "}
                      or email{" "}
                      <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};
