import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { MobileNavBarTab } from "./mobile-nav-bar-tab";

// component that renders the navigation tabs based on user authentication and role
export const MobileNavBarTabs = ({ handleClick }) => {
  // get authentication status and user info from auth0
  const { isAuthenticated, user } = useAuth0();

  // check if user has admin role by looking up role in auth0
  const isAdmin = user?.[window.REACT_APP_AUTH0_ADMIN_ROLES_URL]?.includes(
    window.REACT_APP_AUTH0_ADMIN_ROLE
  );

  return (
    // container for nav tabs
    <div className="mobile-nav-bar__tabs">
      {isAuthenticated && (
        <>
          <MobileNavBarTab
            path="/news"
            label="News"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/resources"
            label="Resources"
            handleClick={handleClick}
          />
          <MobileNavBarTab
            path="/account-management"
            label="Account Management"
            handleClick={handleClick}
          />
        </>
      )}
    </div>
  );
};
