import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { checkIfEmployeeUser } from "../utils";
import React, { useState, useEffect } from "react";
import { getToken } from "../services/token-service";
import { PageLayout } from "../components/page-layout";

// import svg illustrations
import { ReactComponent as APIIcon } from "../assets/apis.svg";
import { ReactComponent as IdeasIcon } from "../assets/ideas.svg";
import { ReactComponent as UniversityIcon } from "../assets/university.svg";
import { ReactComponent as SupportIcon } from "../assets/support-portal.svg";
import { ReactComponent as DsAppsIcon } from "../assets/data-source-apps.svg";
import { ReactComponent as DeveloperIcon } from "../assets/developer-portal.svg";
import { ReactComponent as DocumentationIcon } from "../assets/documentation.svg";
import { ReactComponent as DsConnectorsIcon } from "../assets/data-source-connectors.svg";

// map service names to their icons
const serviceIcons = {
  docs: DocumentationIcon,
  api: APIIcon,
  university: UniversityIcon,
  dsApps: DsAppsIcon,
  dsConnectors: DsConnectorsIcon,
  developer: DeveloperIcon,
  ideas: IdeasIcon,
  supportPortal: SupportIcon,
};

// services that are included with docs access
const DOCS_DEPENDENT_SERVICES = [
  "api",
  "ideas",
  "dsApps",
  "partners",
  "releases",
  "developer",
  "dsConnectors",
];

// render individual resource tile
const ResourceTile = ({ name, link, description, isEnabled, icon: Icon }) => (
  <div
    onClick={() => {
      if (isEnabled) {
        window.open(link, "_blank", "noopener,noreferrer");
      } else {
        window.open(
          window.REACT_APP_SIGNUP_URL,
          "_blank",
          "noopener,noreferrer"
        );
      }
    }}
    className={`resource-tile ${!isEnabled ? "resource-tile--disabled" : ""}`}
    style={{ cursor: "pointer" }}
  >
    <div className="resource-tile__icon">
      <Icon width="100" height="100" />
    </div>
    <h3 className="resource-tile__title">{name}</h3>
    <p className="resource-tile__description">{description}</p>
    {!isEnabled && (
      <div className="resource-tile__request-access">Request Access</div>
    )}
  </div>
);

// render a section of resources
const ResourcesSection = ({
  title,
  resources,
  loading,
  error,
  isProducts = false,
}) => {
  return (
    <div className="form-layout">
      <h1 className="content__subheading">{title}</h1>
      {loading ? (
        <p>Loading your resources...</p>
      ) : error ? (
        <div className="message error-message">
          <p>{error}</p>
        </div>
      ) : resources.length > 0 ? (
        <div className="resources-grid">
          {resources.map((resource, index) => (
            <ResourceTile
              key={index}
              name={resource.name}
              link={resource.link}
              description={resource.description}
              isEnabled={resource.isEnabled}
              icon={serviceIcons[resource.key] || DocumentationIcon}
            />
          ))}
        </div>
      ) : (
        <div className="message">
          <p>
            No {title.toLowerCase()} are currently available for your account.
            Visit{" "}
            <a
              href={`https://${window.REACT_APP_DOMAIN}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {window.REACT_APP_DOMAIN}
            </a>{" "}
            for more information about our products.
          </p>
          <p>
            Please contact{" "}
            <a href={`mailto:${window.REACT_APP_SUPPORT_EMAIL}`}>support</a> if
            you believe this is an error.
          </p>
        </div>
      )}
    </div>
  );
};

// main resources page
export const ResourcesPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [products, setProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // fetch user metadata and update products and services
    const fetchUserMetadata = async () => {
      if (!user) {
        return;
      }

      try {
        setLoading(true);

        const token = await getToken(getAccessTokenSilently);

        const response = await axios.get(
          `${window.REACT_APP_BACKEND_URL}/api/v1/user-metadata`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userId: user.sub,
            },
          }
        );

        // services data
        const ALL_SERVICES = {
          docs: {
            key: "docs",
            name: "Documentation",
            link: window.REACT_APP_DOCS_URL,
            description: "Access comprehensive product documentation",
          },
          supportPortal: {
            key: "supportPortal",
            name: "Support Portal",
            link: window.REACT_APP_SUPPORT_PORTAL_URL,
            description: "Get help from our support team",
          },
          university: {
            key: "university",
            name: "University",
            link: window.REACT_APP_UNIVERSITY_URL,
            description: "Access training and educational resources",
          },
          dsConnectors: {
            key: "dsConnectors",
            name: "DS Connectors",
            link: window.REACT_APP_DS_CONNECTORS_URL,
            description: "Explore available Data Source connections",
          },
          dsApps: {
            key: "dsApps",
            name: "DS Apps",
            link: window.REACT_APP_DS_APPS_URL,
            description: "Explore available Data Sources for applications",
          },
          developer: {
            key: "developer",
            name: "Developer Portal",
            link: window.REACT_APP_DEVELOPER_URL,
            description: "Access developer documentation",
          },
          api: {
            key: "api",
            name: "APIs",
            link: window.REACT_APP_API_URL,
            description: "Access API documentation",
          },
          ideas: {
            key: "ideas",
            name: "Ideas",
            link: window.REACT_APP_IDEAS_URL,
            description: "Share an idea",
          },
          releases: {
            key: "releases",
            name: "Releases",
            link: window.REACT_APP_RELEASES_URL,
            description: "Information about releases",
          },
          partners: {
            key: "partners",
            name: "Partners",
            link: window.REACT_APP_PARTNERS_URL,
            description: "Access the Partner Portal",
          },
        };

        // products data
        const appMetadata = response.data.app_metadata;
        const companyNames =
          appMetadata.tenantConfiguration?.companyNames || [];

        // helper function to check if a string is already a domain or URL
        const isDomainOrUrl = (name) => {
          // check for common patterns that indicate this is already a domain/url:
          // 1. contains a dot followed by at least 2 characters
          // 2. starts with http:// or https://
          return (
            /\.[a-z]{2,}$/i.test(name) || // has a tld
            /^https?:\/\//i.test(name) // starts with http:// or https://
          );
        };

        // updated product list creation with improved domain detection
        const productsList = companyNames.map((companyName) => {
          // check if the company name is already a domain or url
          const isAlreadyDomainOrUrl = isDomainOrUrl(companyName);

          // create the product link based on whether it's already a domain/url
          let productLink;
          if (isAlreadyDomainOrUrl) {
            // if it already starts with http:// or https://, use as is
            if (/^https?:\/\//i.test(companyName)) {
              productLink = companyName;
            } else {
              // it has a tld but no protocol, add https://
              productLink = `https://${companyName}`;
            }
          } else {
            // not a domain, append the cloud domain
            productLink = `https://${companyName}.${window.REACT_APP_CLOUD_DOMAIN}`;
          }

          return {
            name: companyName,
            link: productLink,
            description: `Access ${companyName} environment`,
            isEnabled: true,
          };
        });
        setProducts(productsList);

        // transform services data to include all services with enabled/disabled status
        const servicesList = Object.entries(ALL_SERVICES).map(
          ([key, service]) => {
            // check if user has docs access
            const hasDocsAccess = appMetadata["docs"] === true;

            // determine if the user is an "employee user" based on user_id
            const isEmployeeUser = checkIfEmployeeUser(user.sub);

            // user has direct access to the service
            // user has docs access and this service is in the dependent list
            const isEnabled =
              appMetadata[key] === true ||
              (hasDocsAccess && DOCS_DEPENDENT_SERVICES.includes(key)) ||
              isEmployeeUser;

            return {
              ...service,
              isEnabled,
            };
          }
        );
        setServices(servicesList);
      } catch (error) {
        console.error("Error fetching user metadata:", error);
        setError("Failed to load resources. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchUserMetadata();
    }
  }, [getAccessTokenSilently, user]);

  return (
    <div className="page-background">
      <PageLayout>
        <div className="page-content">
          <div className="content-layout">
            <div className="content__body">
              <div className="content-columns">
                {!checkIfEmployeeUser(user?.sub) && (
                  <div className="content-main">
                    <h1
                      className="content__subheading"
                      style={{ color: "#000000" }}
                    >
                      Your Products
                    </h1>
                    {loading ? (
                      <p>Loading your products...</p>
                    ) : error ? (
                      <div className="message error-message">
                        <p>{error}</p>
                      </div>
                    ) : products.length > 0 ? (
                      <div className="resources-grid">
                        {products.map((product, index) => (
                          <ResourceTile
                            key={index}
                            name={product.name}
                            link={product.link}
                            description={product.description}
                            isEnabled={product.isEnabled}
                            icon={DocumentationIcon}
                          />
                        ))}
                      </div>
                    ) : (
                      <div className="message">
                        <p>
                          No products are currently available for your account.
                          Visit{" "}
                          <a
                            href={`https://${window.REACT_APP_DOMAIN}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {window.REACT_APP_DOMAIN}
                          </a>{" "}
                          for more information about our products.
                        </p>
                        <p style={{ color: "#000000" }}>
                          Please contact{" "}
                          <a
                            href={`mailto:${window.REACT_APP_SUPPORT_EMAIL}`}
                            style={{ color: "#000000" }}
                          >
                            support
                          </a>{" "}
                          if you believe this is an error.
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <div className="content-main">
                  <h1
                    className="content__subheading"
                    style={{ color: "#000000" }}
                  >
                    Your Services
                  </h1>
                  <div className="resources-grid">
                    {!loading &&
                      !error &&
                      services.map((service, index) => (
                        <ResourceTile
                          key={index}
                          name={service.name}
                          link={service.link}
                          description={service.description}
                          isEnabled={service.isEnabled}
                          icon={serviceIcons[service.key] || DocumentationIcon}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};
