import React from "react";
import { NavLink } from "react-router-dom";

// component that renders a navigation tab with active state styling
export const MobileNavBarTab = ({ path, label, handleClick }) => {
  return (
    // navlink provides automatic active state management for routing
    <NavLink
      onClick={handleClick}
      // path where this tab should navigate to
      to={path}
      // end prop ensures exact path matching
      end
      // dynamically set classes based on whether route is active
      className={({ isActive }) =>
        "mobile-nav-bar__tab " + (isActive ? "mobile-nav-bar__tab--active" : "")
      }
    >
      {label}
    </NavLink>
  );
};
