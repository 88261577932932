import React from "react";

// button component for toggling mobile menu visibility
export const MobileMenuToggleButton = ({ icon, handleClick }) => {
  return (
    // span that acts as button with material icon
    <span
      className="mobile-nav-bar__toggle material-icons"
      id="mobile-menu-toggle-button"
      onClick={handleClick}
    >
      {icon}
    </span>
  );
};
