import React from "react";

// signup button that redirects to external signup page
export const SignupButton = () => {
  // redirect to signup url
  const handleSignUp = () => {
    window.location.href = window.REACT_APP_SIGNUP_URL;
  };

  return (
    <button className="button__sign-up" onClick={handleSignUp}>
      Sign Up
    </button>
  );
};
