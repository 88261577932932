import React, { useEffect } from "react";
import { HelpPage } from "./pages/help-page";
import { HomePage } from "./pages/home-page";
import { NewsPage } from "./pages/news-page";
import { useAuth0 } from "@auth0/auth0-react";
import { AdminPage } from "./pages/admin-page";
import { PageLoader } from "./components/page-loader";
import { NotFoundPage } from "./pages/not-found-page";
import { ResourcesPage } from "./pages/resources-page";
import { RoleGuard } from "./components/authorization/role-guard";
import { AuthenticationGuard } from "./components/authentication-guard";
import { AccountManagementPage } from "./pages/account-management-page";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AuthCallbackHandler } from "./components/authorization/auth-callback-handler";

// main app component with routing configuration
export const App = () => {
  // get loading and authentication state from auth0
  const { isLoading, isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();

  // save the current path whenever it changes
  useEffect(() => {
    if (isAuthenticated && location.pathname !== "/") {
      localStorage.setItem("lastPath", location.pathname);
    }
  }, [location, isAuthenticated]);

  // on initial load, check for a saved path
  useEffect(() => {
    if (!isLoading && isAuthenticated && window.location.pathname === "/") {
      const savedPath = localStorage.getItem("lastPath");
      if (savedPath && savedPath !== "/") {
        navigate(savedPath);
      }
    }
  }, [isLoading, isAuthenticated, navigate]);

  // show loader while auth is initializing
  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  // define routes with authentication and role-based guards
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route
        path="/account-management"
        element={<AuthenticationGuard component={AccountManagementPage} />}
      />
      <Route
        path="/resources"
        element={<AuthenticationGuard component={ResourcesPage} />}
      />
      <Route
        path="/admin"
        element={
          <AuthenticationGuard
            component={(RoleGuard, AdminPage)}
            requiredRole={window.REACT_APP_AUTH0_ADMIN_ROLE}
          />
        }
      />
      <Route
        path="/news"
        element={<AuthenticationGuard component={NewsPage} />}
      />
      <Route
        path="/help"
        element={<AuthenticationGuard component={HelpPage} />}
      />
      <Route path="/callback" element={<AuthCallbackHandler />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
