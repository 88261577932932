import axios from "axios";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// dropdown component for user management actions i.e. password and mfa reset
const UserActionDropdown = ({ user, onAction }) => {
  const { getAccessTokenSilently } = useAuth0();
  // state management for ui elements
  const [showDropdown, setShowDropdown] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState(""); // State for dynamic confirm message
  const [error, setError] = useState(null);

  // configuration for different types of confirmation dialogs
  const getConfirmationConfig = (action) => {
    const configs = {
      resetPassword: {
        title: "Reset Password",
        description: user.email,
        confirmationMessage:
          "Are you sure you want to send a password reset email to",
      },
      resetMfa: {
        title: "Reset MFA",
        description: user.email,
        confirmationMessage: "Are you sure you want to reset MFA for",
      },
    };
    return configs[action];
  };

  // handles initial action selection from dropdown
  const handleAction = (action) => {
    setShowDropdown(false);

    // show confirmation dialog for sensitive actions
    if (["resetPassword", "resetMfa"].includes(action)) {
      setCurrentAction(action);
      setConfirmMessage(getConfirmationConfig(action).confirmationMessage); // Set confirmation message
      setShowConfirmDialog(true);
    } else {
      onAction(action, user);
    }
  };

  // handles action confirmation after dialog
  const handleConfirmAction = async () => {
    setShowConfirmDialog(false);
    setError(null);

    try {
      // get auth token for api requests
      const token = await getAccessTokenSilently();

      if (currentAction === "resetMfa") {
        try {
          // disable mfa
          const disableResponse = await axios.post(
            `${window.REACT_APP_BACKEND_URL}/api/v1/mfa/disable`,
            { user_id: user.user_id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (disableResponse.data.success) {
            // set up new mfa
            const setupResponse = await axios.post(
              `${window.REACT_APP_BACKEND_URL}/api/v1/mfa/setup`,
              { user_id: user.user_id },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (setupResponse.data.success) {
              onAction("resetMfa", user, setupResponse.data);
            }
          }
        } catch (error) {
          setError(error.response?.data?.detail || "Failed to reset MFA");
        }
      } else if (currentAction === "resetPassword") {
        onAction("resetPassword", user);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="dropdown">
      <button
        onClick={() => setShowDropdown(!showDropdown)}
        className="dropdown-button"
      >
        <span className="dropdown-tooltip">More Actions</span>
        <Icon icon="material-symbols:more-vert" />
      </button>

      {showDropdown && (
        <>
          <div
            className="dropdown-overlay"
            onClick={() => setShowDropdown(false)}
          />
          <div className="dropdown-menu" style={{ right: 0 }}>
            <button
              onClick={() => handleAction("edit")}
              className="dropdown-item"
            >
              Edit
            </button>
            <button
              onClick={() => handleAction("resetPassword")}
              className="dropdown-item"
            >
              Reset Password
            </button>
            <button
              onClick={() => handleAction("resetMfa")}
              className="dropdown-item"
            >
              Reset MFA
            </button>
          </div>
        </>
      )}

      {showConfirmDialog && (
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-content">
              <h2>
                {currentAction && getConfirmationConfig(currentAction).title}
              </h2>
              <p className="confirmation-text">
                {confirmMessage}
                <br />
                <span className="user-email">
                  {getConfirmationConfig(currentAction).description}
                </span>
                ?
              </p>
              {error && <div className="error-message">{error}</div>}
              <div className="modal-buttons">
                <button
                  className="button button--primary"
                  onClick={handleConfirmAction}
                >
                  Confirm
                </button>
                <button
                  className="button button--secondary"
                  onClick={() => setShowConfirmDialog(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserActionDropdown;
