import React from "react";
import { PageLoader } from "./page-loader";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// wrapper component that ensures routes are only accessible to authenticated users
export const AuthenticationGuard = ({ component }) => {
  // enhance component with auth0 authentication check
  const Component = withAuthenticationRequired(component, {
    // show loading while redirecting to login
    onRedirecting: () => (
      <div className="page-layout">
        <PageLoader />
      </div>
    ),
  });

  // render the protected component
  return <Component />;
};
