import React from "react";
import { PageLayout } from "../components/page-layout";

// landing page that displays company branding
export const HomePage = () => (
  <div className="home-page">
    <PageLayout>
      <div className="home-page-content">
        <div className="customer-portal-title">
          <h1>{window.REACT_APP_BRAND} Customer Portal</h1>
          <p className="customer-portal-subtitle">
            Manage your account and access {window.REACT_APP_BRAND} services and
            products.
          </p>
        </div>
      </div>
    </PageLayout>
  </div>
);
