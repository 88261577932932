import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../../buttons/login-button";
import { SignupButton } from "../../buttons/signup-button";
import Avatar from "react-avatar";

// navigation bar buttons component for authentication
export const MobileNavBarButtons = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);

  // handle user logout with redirect to home page
  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // check if user has admin role
  const isAdmin = user?.[window.REACT_APP_AUTH0_ADMIN_ROLES_URL]?.includes(
    window.REACT_APP_AUTH0_ADMIN_ROLE
  );

  // if user is not authenticated, show login and signup buttons
  if (!isAuthenticated) {
    return (
      <div className="mobile-nav-bar__buttons">
        <>
          <a
            href="/help"
            className="button__help"
            style={{
              color: "var(--primary-color)",
              textDecoration: "none",
              fontWeight: "bold",
              marginRight: "1rem",
            }}
          >
            Help
          </a>
          <SignupButton />
          <LoginButton />
        </>
      </div>
    );
  }

  // get user display name and initial for avatar
  const userName = user.name || user.nickname || user.email;
  const userInitial = userName.charAt(0).toUpperCase();

  // render authenticated user view with dropdown menu
  return (
    <div className="mobile-nav-bar__buttons">
      <div className="dropdown" style={{ marginRight: "1rem", width: "100%" }}>
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className="button button--secondary"
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Avatar
            name={userName}
            size="35"
            round={true}
            maxInitials={2}
            value={userInitial}
          />
        </button>
        {showDropdown && (
          <div
            className="dropdown-menu"
            style={{
              right: 0,
              width: "100%",
              backgroundColor: "white",
              fontSize: "1.5em",
            }}
          >
            <div
              className="dropdown-item"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
              }}
            >
              <Avatar
                name={userName}
                size="35"
                round={true}
                maxInitials={1}
                value={userInitial}
                style={{ marginRight: "0.5rem" }}
              />
              <div>
                <p style={{ margin: 0, fontWeight: "bold" }}>{userName}</p>
                <p style={{ margin: 0 }}>{user.email}</p>
              </div>
            </div>
            {}
            <a
              href="/help"
              className="dropdown-item"
              style={{
                color: "black",
                fontWeight: "bold",
                textDecoration: "none",
                textAlign: "center",
              }}
              onClick={() => setShowDropdown(false)}
            >
              Help
            </a>
            {window.REACT_APP_COMMUNICATIONS_URL && (
              <a
                href={window.REACT_APP_COMMUNICATIONS_URL}
                className="dropdown-item"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                  textAlign: "center",
                }}
                onClick={() => setShowDropdown(false)}
              >
                Communications
              </a>
            )}
            {isAdmin && (
              <a
                href="/admin"
                className="dropdown-item"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                  textAlign: "center",
                }}
              >
                Admin
              </a>
            )}
            <button
              onClick={handleLogout}
              className="dropdown-item"
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
