// token caching service to minimize auth0 api calls
let cachedToken = null;
let tokenExpiryTime = null;

export const getToken = async (getAccessTokenSilently) => {
  const currentTime = Date.now();

  // if we have a cached token that's not expired (with 5 min buffer), return it
  if (
    cachedToken &&
    tokenExpiryTime &&
    currentTime < tokenExpiryTime - 300000
  ) {
    return cachedToken;
  }

  try {
    // get a new token with detailed response to check expiry
    const tokenResponse = await getAccessTokenSilently({
      cacheMode: "cache-first",
      detailedResponse: true,
      timeoutInSeconds: 60,
    });

    // cache the token and its expiry time
    cachedToken = tokenResponse.access_token;
    tokenExpiryTime = currentTime + tokenResponse.expires_in * 1000;

    return cachedToken;
  } catch (error) {
    console.error("Error getting token:", error);
    throw error;
  }
};
