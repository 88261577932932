import React from "react";
import { NavBarTab } from "./nav-bar-tab";
import { useAuth0 } from "@auth0/auth0-react";

// component that renders the navigation tabs based on user authentication and role
export const NavBarTabs = () => {
  // get authentication status and user info from auth0
  const { isAuthenticated, user } = useAuth0();

  // check if user has admin role by looking up role in auth0
  const isAdmin = user?.[window.REACT_APP_AUTH0_ADMIN_ROLES_URL]?.includes(
    window.REACT_APP_AUTH0_ADMIN_ROLE
  );

  return (
    // container for nav tabs
    <div className="nav-bar__tabs">
      {isAuthenticated && (
        <>
          <NavBarTab path="/news" label="News" />
          <NavBarTab path="/resources" label="Resources" />
          <NavBarTab path="/account-management" label="Account Management" />
        </>
      )}
    </div>
  );
};
