import Avatar from "react-avatar";
import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "../../buttons/login-button";
import { SignupButton } from "../../buttons/signup-button";

// navigation bar buttons component for authentication
export const NavBarButtons = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  // check if user has admin role
  const isAdmin = user?.[window.REACT_APP_AUTH0_ADMIN_ROLES_URL]?.includes(
    window.REACT_APP_AUTH0_ADMIN_ROLE
  );

  // if user is not authenticated, show login and signup buttons
  if (!isAuthenticated) {
    return (
      <div className="nav-bar__buttons">
        <SignupButton />
        <LoginButton />
      </div>
    );
  }

  // get user display name and initial for avatar
  const userName = user.name || user.nickname || user.email;
  const userInitial = userName.charAt(0).toUpperCase();

  // render authenticated user view with dropdown menu
  return (
    <div className="nav-bar__buttons">
      <div className="dropdown" style={{ marginRight: "1rem" }}>
        <button
          onClick={() => setShowDropdown(!showDropdown)}
          className="button button--secondary"
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
          }}
        >
          <Avatar
            name={userName}
            size="35"
            round={true}
            maxInitials={2}
            value={userInitial}
            style={{ marginRight: "0.5rem" }}
          />
        </button>
        {showDropdown && (
          <div
            className="dropdown-menu"
            style={{ right: 0, backgroundColor: "white", fontSize: "1.5em" }}
          >
            <div
              className="dropdown-item"
              style={{ display: "flex", alignItems: "center", color: "black" }}
            >
              <Avatar
                name={userName}
                size="35"
                round={true}
                maxInitials={1}
                value={userInitial}
                style={{ marginRight: "0.5rem" }}
              />
              <div>
                <p style={{ margin: 0, fontWeight: "bold" }}>{userName}</p>
                <p style={{ margin: 0 }}>{user.email}</p>
              </div>
            </div>
            {isAdmin && (
              <a
                href="/admin"
                className="dropdown-item"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="material-symbols:admin-panel-settings-outline"
                  style={{ marginRight: "8px" }}
                />
                Admin
              </a>
            )}
            <a
              href="/help"
              className="dropdown-item"
              onClick={() => setShowDropdown(false)}
              style={{
                color: "black",
                fontWeight: "bold",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                icon="material-symbols:help"
                style={{ marginRight: "8px" }}
              />
              Help
            </a>
            {window.REACT_APP_COMMUNICATIONS_URL && (
              <a
                href={window.REACT_APP_COMMUNICATIONS_URL}
                className="dropdown-item"
                onClick={() => setShowDropdown(false)}
                style={{
                  color: "black",
                  fontWeight: "bold",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon
                  icon="material-symbols:business-messages"
                  style={{ marginRight: "8px" }}
                />
                Communications
              </a>
            )}
            <button
              onClick={handleLogout}
              className="dropdown-item"
              style={{
                color: "black",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                icon="material-symbols:logout"
                style={{ marginRight: "8px" }}
              />
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
