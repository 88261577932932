import React from "react";
import "../styles/news-feed.css";
import { NewsFeed } from "../components/news-feed";
import { PageLayout } from "../components/page-layout";

export const NewsPage = () => {
  return (
    <div className="page-background">
      <PageLayout>
        <div className="page-content">
          <div className="content-layout">
            <div className="content__body">
              <NewsFeed />
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};
