// helper function to check if user is employee
export const checkIfEmployeeUser = (userId) => {
  if (!userId) {
    return false;
  }
  const parts = userId.split("|");
  if (parts.length === 3 && parts[1] === window.REACT_APP_EMPLOYEE_CONNECTION) {
    return true;
  }
  return false;
};
