import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo_next.svg";
import brandLogo from "../../../assets/brand_next.svg";

// navigation bar brand component with company logo and brand logo
export const NavBarBrand = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/" className="nav-bar__link">
        <img src={logo} alt="Logo" className="nav-bar__logo" height="60" />
        <img
          src={brandLogo}
          alt={window.REACT_APP_BRAND}
          className="nav-bar__brand-logo"
          height="30"
          style={{ marginLeft: "12px" }}
        />
      </NavLink>
    </div>
  );
};
