import React, { useEffect } from "react";
import { PageLoader } from "../page-loader";
import { useNavigate } from "react-router-dom";

export const AuthCallbackHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // extract the hash
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);

    // get the token and state
    const accessToken = params.get("access_token");
    const state = params.get("state");

    console.log("Callback handler received:", {
      accessToken: !!accessToken,
      state,
    });

    if (accessToken && state) {
      // store the token and state in session storage
      sessionStorage.setItem("callback_token", accessToken);
      sessionStorage.setItem("callback_state", state);

      // redirect to the appropriate page based on state
      if (state === "disable_mfa") {
        console.log("Redirecting to account management page");
        navigate("/account-management");
      } else {
        navigate("/");
      }
    } else {
      // no token or state, redirect to home
      console.log("No token or state found, redirecting to home");
      navigate("/");
    }
  }, [navigate]);

  return (
    <div className="page-layout">
      <PageLoader />
    </div>
  );
};
