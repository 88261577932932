import axios from "axios";

// create an axios instance with default config
const apiClient = axios.create({
  timeout: 10000, // 10 second timeout
  headers: {
    "Content-Type": "application/json",
  },
});

// helper to wait between retries
const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// reusable fetch function with retry logic
export const fetchWithRetry = async ({
  url,
  method = "GET",
  data = null,
  retries = 3,
  retryDelay = 1000,
  onError = null,
}) => {
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      const response = await apiClient({
        url,
        method,
        data,
        // cancel request if component unmounts
        signal: new AbortController().signal,
      });

      return response.data;
    } catch (error) {
      // handle specific error cases
      if (error.response) {
        // server responded with error status
        if (error.response.status === 429) {
          // rate limited get retry delay from headers or use default
          const retryAfter =
            error.response.headers["retry-after"] || retryDelay;
          await wait(retryAfter * 1000);
          continue;
        }

        if (error.response.status >= 400 && error.response.status < 500) {
          // don't retry client errors except rate limiting
          throw new Error(
            `Request failed: ${error.response.data.message || error.message}`
          );
        }
      }

      if (error.code === "ECONNABORTED") {
        // timeout error
        throw new Error("Request timed out");
      }

      // for network errors or 500s, retry if attempts remaining
      if (attempt < retries) {
        await wait(retryDelay * Math.pow(2, attempt)); // exponential backoff
        continue;
      }

      // call error callback if provided
      if (onError) {
        onError(error);
      }

      // no more retries throw error
      throw new Error(
        `Request failed after ${retries} attempts: ${error.message}`
      );
    }
  }
};
