import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { checkIfEmployeeUser } from "../utils";
import React, { useState, useEffect } from "react";
import { getToken } from "../services/token-service";
import { PageLayout } from "../components/page-layout";

// confirm password validation component
const ConfirmPasswordValidation = ({ newPassword, confirmPassword }) => {
  // only show validation when user has started typing in confirm field
  if (!confirmPassword) {
    return null;
  }

  const passwordsMatch = newPassword === confirmPassword;

  // if passwords match, hide the toast
  if (passwordsMatch) {
    return null;
  }

  return (
    <div className="password-match-toast">
      <div className="password-match-content">
        <span className="match-status mismatch">Passwords do not match</span>
      </div>
    </div>
  );
};

const validatePassword = (password) => {
  // create an object to track each requirement's status
  const requirements = {
    length: {
      met: password && password.length >= 8,
      message: "Be at least 8 characters long",
    },
    noRepeating: {
      met: password && !/(.)\1{2,}/.test(password),
      message: "Not have more than 2 identical characters in a row",
    },
    specialChar: {
      met: password && /[!@#$%^&*]/.test(password),
      message: "Contain at least one special character (!@#$%^&*)",
    },
    lowercase: {
      met: password && /[a-z]/.test(password),
      message: "Include lowercase letters",
    },
    uppercase: {
      met: password && /[A-Z]/.test(password),
      message: "Include uppercase letters",
    },
    numbers: {
      met: password && /[0-9]/.test(password),
      message: "Include numbers",
    },
  };

  // check if all requirements are met
  const allRequirementsMet = Object.values(requirements).every(
    (req) => req.met
  );

  return {
    isValid: allRequirementsMet,
    requirements,
    message: allRequirementsMet
      ? ""
      : "Password does not meet all requirements",
  };
};

// password requirements toast component
const PasswordRequirementsToast = ({ password }) => {
  const validation = validatePassword(password);

  // if all requirements are met, hide the toast
  if (validation.isValid) {
    return null;
  }

  return (
    <div className="password-requirements-toast">
      <h4 style={{ fontSize: "1.275rem", fontWeight: "normal" }}>
        Password must:
      </h4>
      <ul>
        {Object.entries(validation.requirements).map(([key, requirement]) => (
          <li
            key={key}
            className={
              password
                ? requirement.met
                  ? "requirement-met"
                  : "requirement-failed"
                : "requirement-pending"
            }
            style={{ fontSize: "1.275rem" }}
          >
            {requirement.message}
          </li>
        ))}
      </ul>
      <p
        className="password-note"
        style={{ fontSize: "1.275rem", color: "white" }}
      >
        Note: Choose a password that hasn't been used in your last 5 passwords.
      </p>
    </div>
  );
};

// component to display jwt token
const TokenDisplayForm = ({ token, isLoading }) => {
  const [isTokenVisible, setIsTokenVisible] = useState(false);

  // function to mask the token
  const maskToken = (token) => {
    if (!token) return "";
    return "•".repeat(token.length);
  };

  return (
    <div className="form-layout">
      <h1 className="content__subheading" style={{ color: "#000000" }}>
        Access Token
      </h1>
      <div className="form-group">
        <textarea
          className="token-textarea"
          value={
            token
              ? isTokenVisible
                ? token
                : maskToken(token)
              : "No token available"
          }
          readOnly
        />
      </div>
      <div className="button-container">
        <button
          onClick={() => setIsTokenVisible(!isTokenVisible)}
          className="button button--primary form-button"
          disabled={!token}
          style={{ marginRight: "10px" }}
        >
          {isTokenVisible ? "Hide Token" : "Reveal Token"}
        </button>
        <button
          onClick={async () => {
            if (token) {
              try {
                await navigator.clipboard.writeText(token);
              } catch (err) {}
            }
          }}
          className="button button--primary form-button"
          disabled={!token}
        >
          Copy Token
        </button>
      </div>
    </div>
  );
};

// toast notification component for displaying messages
const Toast = ({ message, onClose }) => {
  useEffect(() => {
    // automatically close toast after 3 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    // cleanup timer
    return () => clearTimeout(timer);
  }, [onClose]);

  return <div className="toast-notification">{message}</div>;
};

// form component for changing password
const ChangePasswordForm = ({
  handlePasswordChange,
  newPassword,
  setNewPassword,
  confirmPassword,
  setConfirmPassword,
  isLoading,
}) => (
  <form onSubmit={handlePasswordChange} className="form-layout">
    <h1 className="content__subheading" style={{ color: "#000000" }}>
      Change Password
    </h1>
    <div className="form-group">
      <label htmlFor="newPassword" style={{ color: "#000000" }}>
        New Password:
      </label>
      <div style={{ position: "relative" }}>
        <input
          type="password"
          id="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        {/* password requirements toast is shown whenever the input field has content */}
        {newPassword.length > 0 && (
          <PasswordRequirementsToast password={newPassword} />
        )}
      </div>
    </div>
    <div className="form-group">
      <label htmlFor="confirmPassword" style={{ color: "#000000" }}>
        Confirm New Password:
      </label>
      <div style={{ position: "relative" }}>
        <input
          type="password"
          id="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
        <ConfirmPasswordValidation
          newPassword={newPassword}
          confirmPassword={confirmPassword}
        />
      </div>
    </div>
    <div className="button-container">
      <button
        type="submit"
        className="button button--primary form-button"
        disabled={
          isLoading || (confirmPassword && newPassword !== confirmPassword)
        }
      >
        {isLoading ? "Changing Password..." : "Change Password"}
      </button>
    </div>
  </form>
);

// form component for managing mfa settings
const MfaForm = ({
  mfaEnabled,
  handleMfaToggle,
  mfaLoading,
  isStepUpInProgress,
}) => (
  <div className="form-layout">
    <h1 className="content__subheading" style={{ color: "#000000" }}>
      Multi-Factor Authentication
    </h1>
    <p style={{ color: "#000000" }}>
      MFA is currently {mfaEnabled ? "enabled" : "disabled"}.
    </p>
    <div className="button-container">
      <button
        onClick={handleMfaToggle}
        className="button button--primary form-button"
        disabled={mfaLoading || isStepUpInProgress}
      >
        {mfaLoading
          ? "Processing..."
          : isStepUpInProgress
          ? "Redirecting for verification..."
          : mfaEnabled
          ? "Disable MFA"
          : "Enable MFA"}
      </button>
    </div>
  </div>
);

// main account management page component
export const AccountManagementPage = () => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [mfaLoading, setMfaLoading] = useState(false);
  const [toast, setToast] = useState(null);
  const [token, setToken] = useState("");
  const [tokenLoading, setTokenLoading] = useState(false);

  // state for logout notification
  const [showLogoutNotification, setShowLogoutNotification] = useState(false);
  const [logoutReason, setLogoutReason] = useState("");
  const [logoutCountdown, setLogoutCountdown] = useState(10);

  // state for step-up authentication
  const [isStepUpInProgress, setIsStepUpInProgress] = useState(false);

  // fetch mfa status when user data is available
  useEffect(() => {
    fetchMfaStatus();
  }, [user]);

  // fetch token when component mounts
  useEffect(() => {
    fetchToken();
  }, []);

  // handle redirect from step-up authentication
  useEffect(() => {
    // check if this is a callback from step-up authentication
    const params = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = params.get("access_token");
    const state = params.get("state");

    if (accessToken && state === "disable_mfa") {
      // returned from step-up auth with a token
      // complete the MFA disable process
      completeMfaDisable(accessToken);

      // clear the url hash to avoid issues on refresh
      window.history.replaceState(null, null, window.location.pathname);
    }

    // check for callback token from session storage
    const callbackToken = sessionStorage.getItem("callback_token");
    const callbackState = sessionStorage.getItem("callback_state");

    if (callbackToken && callbackState === "disable_mfa") {
      // clear the session storage
      sessionStorage.removeItem("callback_token");
      sessionStorage.removeItem("callback_state");

      // complete the mfa disable process
      completeMfaDisable(callbackToken);
    }
  }, [user]);

  // fetch current access token
  const fetchToken = async () => {
    try {
      setTokenLoading(true);
      const token = await getToken(getAccessTokenSilently);
      setToken(token);
    } catch (error) {
      showToast("Failed to fetch access token. Please try again.");
    } finally {
      setTokenLoading(false);
    }
  };

  // displays notification message
  const showToast = (message) => {
    setToast(message);
  };

  // handle forced logout with countdown
  const handleForcedLogout = (reason, message) => {
    setLogoutReason(reason);
    setLogoutCountdown(10);
    setShowLogoutNotification(true);

    // start countdown and execute logout when it reaches 0
    const countdownInterval = setInterval(() => {
      setLogoutCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(countdownInterval);
          // execute logout after countdown reaches 0
          setTimeout(() => {
            logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }, 500);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  // retrieves current mfa status from backend
  const fetchMfaStatus = async () => {
    if (!user) {
      return;
    }

    try {
      setMfaLoading(true);
      const token = await getToken(getAccessTokenSilently);

      const response = await axios.get(
        `${window.REACT_APP_BACKEND_URL}/api/v1/mfa/status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.mfaEnabled !== undefined) {
        setMfaEnabled(response.data.mfaEnabled);
      } else {
        showToast("Failed to determine MFA status. Please try again.");
      }
    } catch (error) {
      showToast("Failed to fetch MFA status. Please try again.");
    } finally {
      setMfaLoading(false);
    }
  };

  // password change submission and validation
  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // first validate password match
    if (newPassword !== confirmPassword) {
      showToast("New passwords do not match");
      setIsLoading(false);
      return;
    }

    // validate password requirements
    const validationResult = validatePassword(newPassword);
    if (!validationResult.isValid) {
      showToast(validationResult.message);
      setIsLoading(false);
      return;
    }

    try {
      const token = await getToken(getAccessTokenSilently);

      // send password change request to backend
      await axios.post(
        `${window.REACT_APP_BACKEND_URL}/api/v1/change-password`,
        {
          userId: user.sub,
          newPassword: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      handleForcedLogout("Successfully changed your password.");

      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      // check if the error response indicates a previous password
      if (error.response?.status === 400) {
        showToast(
          "This appears to be a previously used password. Please try a different password."
        );
      } else {
        showToast("Failed to change password. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // complete mfa disable after step-up authentication
  const completeMfaDisable = async (token) => {
    setMfaLoading(true);
    try {
      console.log("Completing MFA disable with token");

      // use token received from step-up auth
      const response = await axios.post(
        `${window.REACT_APP_BACKEND_URL}/api/v1/mfa/disable`,
        {
          user_id: user.sub,
          // flag to indicate this is user-initiated disable and not admin
          is_self_service: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        setMfaEnabled(false);
        // invoke logout with success message
        handleForcedLogout("Successfully disabled your MFA.");
      }
    } catch (error) {
      console.error("Error disabling MFA:", error);
      showToast(
        `Failed to disable MFA: ${
          error.response?.data?.detail || error.message
        }`
      );
    } finally {
      setMfaLoading(false);
      setIsStepUpInProgress(false);
    }
  };

  // enable / disable mfa
  const handleMfaToggle = async () => {
    setMfaLoading(true);
    try {
      const token = await getToken(getAccessTokenSilently);

      if (mfaEnabled) {
        // disable mfa, initiate step-up authentication
        setIsStepUpInProgress(true);

        // use the callback url
        const redirectUri = `${window.location.origin}/callback`;

        // construct the auth0 authorize url
        const authUrl =
          `https://${window.REACT_APP_FRONTEND_DOMAIN}/authorize?` +
          `response_type=token&` +
          `client_id=${window.REACT_APP_FRONTEND_CLIENT_ID}&` +
          `redirect_uri=${window.REACT_APP_FRONTEND_CALLBACK_URL}&` +
          `scope=openid%20profile%20email&` +
          `audience=${window.REACT_APP_FRONTEND_AUDIENCE}&` +
          `state=disable_mfa&` +
          `prompt=login`;

        console.log(
          "Redirecting to Auth0 for step-up authentication:",
          authUrl
        );

        // redirect to auth0 for step-up authentication
        window.location.href = authUrl;
        return; // exit early as we're redirecting
      } else {
        // enabling mfa, use the existing flow
        await axios.post(
          `${window.REACT_APP_BACKEND_URL}/api/v1/mfa/setup`,
          { user_id: user.sub },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        handleForcedLogout(
          <span>
            MFA enrollment email sent. Please check <b>{user.email}</b> for MFA
            setup information.
          </span>
        );
      }
    } catch (error) {
      showToast(`Failed to update MFA settings: ${error.message}`);
      setIsStepUpInProgress(false);
    } finally {
      if (!isStepUpInProgress) {
        setMfaLoading(false);
      }
    }
  };

  // logout notification component
  const LogoutNotification = () => {
    if (!showLogoutNotification) return null;

    return (
      <div className="logout-notification-overlay">
        <div className="logout-notification-dialog">
          <p>{logoutReason}</p>
          <p className="logout-countdown">
            Logging out in {logoutCountdown} seconds...
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="page-background">
      <PageLayout>
        <div className="page-content">
          <div className="content-layout">
            <div className="content__body">
              {}
              <LogoutNotification />
              {toast && (
                <Toast message={toast} onClose={() => setToast(null)} />
              )}
              {!checkIfEmployeeUser(user?.sub) && (
                <>
                  <ChangePasswordForm
                    handlePasswordChange={handlePasswordChange}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    isLoading={isLoading}
                  />
                  <div className="form-separator"></div>
                </>
              )}
              {!checkIfEmployeeUser(user?.sub) && (
                <>
                  <MfaForm
                    mfaEnabled={mfaEnabled}
                    handleMfaToggle={handleMfaToggle}
                    mfaLoading={mfaLoading}
                    isStepUpInProgress={isStepUpInProgress}
                  />
                  <div className="form-separator"></div>
                </>
              )}
              <TokenDisplayForm token={token} isLoading={tokenLoading} />
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};
