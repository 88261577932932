import { useNavigate } from "react-router-dom";
import { getToken } from "./services/token-service";
import { PageLoader } from "./components/page-loader";
import React, { useState, useEffect, useRef } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

// separate component to load config after auth
const ConfigLoader = ({ children }) => {
  const [configLoaded, setConfigLoaded] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const configRef = useRef(null);

  useEffect(() => {
    const initConfig = async () => {
      try {
        // if we've already loaded the config, don't do it again
        if (configRef.current) {
          setConfigLoaded(true);
          return;
        }

        if (isAuthenticated) {
          // use token service instead of direct getAccessTokenSilently
          const token = await getToken(getAccessTokenSilently);

          // get initial backend url from the auth0 config file
          const backendUrl = window.REACT_APP_BACKEND_URL;
          const response = await fetch(`${backendUrl}/api/v1/config`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          if (!response.ok) {
            throw new Error(`Failed to fetch config: ${response.statusText}`);
          }

          const config = await response.json();

          // store config in ref to avoid refetching
          configRef.current = config;

          // set all environment variables from config
          Object.entries(config).forEach(([key, value]) => {
            window[key] = value;
          });

          // Re-set backend URL to ensure it's available
          if (config.REACT_APP_BACKEND_URL) {
            window.REACT_APP_BACKEND_URL = config.REACT_APP_BACKEND_URL;
          }
        }
      } catch (error) {
        console.error("Error loading config:", error);
      } finally {
        setConfigLoaded(true);
      }
    };

    initConfig();
  }, [getAccessTokenSilently, isAuthenticated]);

  if (!configLoaded) return <PageLoader />;
  return children;
};

export const Auth0ProviderWithNavigate = ({ children }) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (
    !(
      window.REACT_APP_FRONTEND_DOMAIN &&
      window.REACT_APP_FRONTEND_CLIENT_ID &&
      window.REACT_APP_FRONTEND_CALLBACK_URL &&
      window.REACT_APP_FRONTEND_AUDIENCE
    )
  ) {
    return null;
  }

  return (
    <Auth0Provider
      domain={window.REACT_APP_FRONTEND_DOMAIN}
      clientId={window.REACT_APP_FRONTEND_CLIENT_ID}
      authorizationParams={{
        audience: window.REACT_APP_FRONTEND_AUDIENCE,
        redirect_uri: window.REACT_APP_FRONTEND_CALLBACK_URL,
        // add this to increase token lifetime if your auth0 tenant allows it
        max_age: 86400, // 24 hours
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      <ConfigLoader>{children}</ConfigLoader>
    </Auth0Provider>
  );
};
