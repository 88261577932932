import axios from "axios";
import Avatar from "react-avatar";
import { useAuth0 } from "@auth0/auth0-react";
import { getToken } from "../services/token-service";
import { PageLayout } from "../components/page-layout";
import EditUserModal from "../components/admin/EditUserModal";
import React, { useState, useEffect, useCallback } from "react";
import UserActionDropdown from "../components/admin/UserActionDropdown";

// notification that auto-dismisses after 3 seconds
const Toast = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return <div className="toast-notification">{message}</div>;
};

// displays managed domains for the admin user
const DomainInfo = ({ domains }) => (
  <div className="domain-info">
    <div className="domain-tags">
      {domains.map((domain) => (
        <span key={domain} className="domain-tag">
          @{domain}
        </span>
      ))}
    </div>
  </div>
);

// table to display user data
const UsersList = ({ users, loading, error, onEditUser, handleUserAction }) => (
  <div>
    {loading ? (
      <p>Loading users...</p>
    ) : error ? (
      <div className="message error-message">
        <p>{error}</p>
      </div>
    ) : users.length === 0 ? (
      <p>No users found for your managed domains.</p>
    ) : (
      <div className="users-list">
        <table className="users-table" style={{ minWidth: "800px" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>MFA</th>
              <th>Email Verified</th>
              <th>Latest Login</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id + user.email}>
                <td>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar
                      name={user.name || user.email}
                      size="30"
                      round={true}
                      maxInitials={2}
                      style={{ marginRight: "8px" }}
                    />
                    <div>
                      <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                        {user.name || "N/A"}
                      </div>
                      <div style={{ color: "grey", fontSize: "12px" }}>
                        {user.email}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {user.mfa_enabled ? (
                    <span>Enabled</span>
                  ) : (
                    <span style={{ color: "grey" }}>Disabled</span>
                  )}
                </td>
                <td>
                  {user.email_verified ? (
                    <span>Verified</span>
                  ) : (
                    <span style={{ color: "grey" }}>Not Verified</span>
                  )}
                </td>
                <td>
                  {user.last_login ? (
                    <span style={{ fontSize: "14px" }}>
                      {new Date(user.last_login).toLocaleDateString(undefined, {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  ) : (
                    <span style={{ color: "grey", fontSize: "14px" }}>
                      Never
                    </span>
                  )}
                </td>
                <td>
                  <UserActionDropdown user={user} onAction={handleUserAction} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
);

export const AdminPage = () => {
  // auth and user state management
  const { getAccessTokenSilently, user } = useAuth0();
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editingUser, setEditingUser] = useState(null);
  const [managedDomains, setManagedDomains] = useState([]);
  const [toast, setToast] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // fetch users data from api
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getToken(getAccessTokenSilently);

      const response = await axios.get(
        `${window.REACT_APP_BACKEND_URL}/api/v1/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const filteredUsers = response.data.filter(
        (fetchedUser) => fetchedUser.user_id !== user.sub
      );

      setUsers(filteredUsers);
      setError(null);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to load users. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, user?.sub]); // Add user.sub to dependency array

  // extract managed domains from user metadata
  useEffect(() => {
    if (user?.user_metadata?.customerPortal?.companyDomains) {
      setManagedDomains(user.user_metadata.customerPortal.companyDomains);
    }
  }, [user]);

  // fetch users
  useEffect(() => {
    if (!isRefreshing) {
      fetchUsers();
    }
  }, [fetchUsers, isRefreshing]);

  // add delay before refreshing to allow backend to update
  const refreshWithDelay = async () => {
    setIsRefreshing(true);
    await new Promise((resolve) => setTimeout(resolve, 1500));
    await fetchUsers();
    setIsRefreshing(false);
  };

  const showToast = (message) => {
    setToast(message);
  };

  // user actions (edit profile, reset password, and reset mfa)
  const handleUserAction = async (action, targetUser, response) => {
    try {
      const token = await getToken(getAccessTokenSilently);

      switch (action) {
        case "edit":
          setEditingUser(targetUser);
          break;

        case "resetPassword":
          try {
            const resetResponse = await axios.post(
              `${window.REACT_APP_BACKEND_URL}/api/v1/admin/users/${targetUser.user_id}/reset-password`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            showToast("Password reset email sent successfully");
            await refreshWithDelay();
          } catch (error) {
            console.error("Error resetting password:", error);
            setError(
              error.response?.data?.detail || "Failed to reset password"
            );
          }
          break;

        case "resetMfa":
          if (response?.success) {
            showToast("MFA reset was successfull");
            await refreshWithDelay();
          }
          break;

        default:
          console.warn(`Unhandled action: ${action}`);
      }
    } catch (error) {
      console.error(`Error handling action ${action}:`, error);
      setError(error.response?.data?.detail || `Failed to perform ${action}`);
    }
  };

  // user data updates
  const handleSaveUser = async (formData) => {
    setError(null);
    setLoading(true);

    try {
      const token = await getToken(getAccessTokenSilently);

      await axios.patch(
        `${window.REACT_APP_BACKEND_URL}/api/v1/admin/users/${editingUser.user_id}`,
        {
          email: formData.email,
          name: formData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setEditingUser(null);
      showToast("User updated successfully");
      await refreshWithDelay();
    } catch (error) {
      console.error("Error updating user:", error);
      setError(
        error.response?.data?.detail ||
          "Failed to update user. Please try again."
      );
      setLoading(false);
    }
  };

  // render admin page layout
  return (
    <div className="page-background admin-page">
      {}
      {toast && <Toast message={toast} onClose={() => setToast(null)} />}
      <PageLayout>
        <div className="page-content">
          <div className="content-layout">
            <div className="content__body">
              <h1 id="page-title" className="content__title">
                User Management
              </h1>
              {error && <div className="error-message">{error}</div>}
              <DomainInfo domains={managedDomains} />
              <UsersList
                users={users}
                loading={loading}
                error={error}
                onEditUser={setEditingUser}
                handleUserAction={handleUserAction}
              />
              {editingUser && (
                <EditUserModal
                  user={editingUser}
                  onClose={() => setEditingUser(null)}
                  onSave={handleSaveUser}
                />
              )}
            </div>
          </div>
        </div>
      </PageLayout>
    </div>
  );
};
