import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/logo_next.svg";
import brandLogo from "../../../assets/brand_next.svg";

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div className="mobile-nav-bar__brand">
      <NavLink to="/" className="mobile-nav-bar__link" onClick={handleClick}>
        <img
          src={logo}
          alt="Logo"
          className="mobile-nav-bar__logo"
          height="60"
        />
        <img
          src={brandLogo}
          alt={window.REACT_APP_BRAND}
          className="mobile-nav-bar__brand-logo"
          height="30"
          style={{ marginLeft: "10px" }}
        />
      </NavLink>
    </div>
  );
};
