import React, { useState, useEffect } from "react";

const EditUserModal = ({ user, onClose, onSave }) => {
  // initialize form state with user data
  const [formData, setFormData] = useState({
    email: user.email,
    name: user.name || "",
  });
  // state for storing and displaying error info
  const [debugInfo, setDebugInfo] = useState(null);

  // log initial user data when component mounts
  useEffect(() => {}, [user]);

  // handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // prepare update data by combining form data with existing metadata
    const updateData = {
      ...formData,
      app_metadata: {
        ...user.app_metadata,
      },
    };

    // attempt to save changes and handle any errors
    try {
      await onSave(updateData);
    } catch (error) {
      console.error("Error in EditUserModal submit:", error);
      setDebugInfo(error.response?.data || error.message);
    }
  };

  // handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, [onClose]);

  return (
    // modal overlay provides dark background
    <div
      className="modal-overlay"
      onClick={(e) => e.target === e.currentTarget && onClose()}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2 id="modal-title">Edit User</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value });
                }}
                required
              />
            </div>

            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => {
                  setFormData({ ...formData, name: e.target.value });
                }}
              />
            </div>

            {debugInfo && (
              <div className="debug-info">
                <h3>Debug Information</h3>
                <pre>{JSON.stringify(debugInfo, null, 2)}</pre>
              </div>
            )}

            <div className="modal-buttons">
              <button type="submit" className="button button--primary">
                Confirm
              </button>
              <button
                type="button"
                className="button button--secondary"
                style={{
                  backgroundColor: "#ffffff",
                  color: "#000000",
                }}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
